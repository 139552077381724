var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"hello",attrs:{"id":"first"}},[_c('h1',[_vm._v(_vm._s(_vm.msg))])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"second"}},[_c('h1',[_vm._v("driverJS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"img1"}},[_c('img',{attrs:{"src":require("@/assets/1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"img2"}},[_c('img',{attrs:{"src":require("@/assets/2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"img3"}},[_c('img',{attrs:{"src":require("@/assets/3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"img4"}},[_c('img',{attrs:{"src":require("@/assets/4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"img5"}},[_c('img',{attrs:{"src":require("@/assets/5.png"),"alt":""}})])
}]

export { render, staticRenderFns }